











































































































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import DialogComponent from '@/components/dialog.component.vue';
import { mixins } from 'vue-class-component';
import draggable from 'vuedraggable';
import { APPIDS, COURSE_TYPE, RESERVE_EXAM_ROUTE_NAME } from '../../enums';
import ReserveExamProvider from '../../providers/reserveExam.provider';
import Toaster from '../../mixins/toaster.mixin';

import {
  ExamDropInput,
  ReservationRequest,
  ReservationAddFiltersTypes,
  ImportStudentsResponse,
  SaveReservationResponse,
  CenterDropInput,
  BookedTimeSlot,
} from '../../types/exam.type';
import { Lang } from '../../types/general.type';
// import Utils from '../../shared/utils';

@Component({
  components: {
    DialogComponent,
    draggable,
  },
})
export default class AddReservation extends mixins(Toaster) {
  toMenu = false;

  fromMenu = false;

  courseId = '';

  err = '';

  appId = APPIDS.RESERVE_EXAM;

  showCancelConfirm = false;

  loading = false;

  importLoading = false;

  showSaveConfirm = false;

  filtersData: ReservationAddFiltersTypes = {
    academicYears: [],
    centers: [],
    examTypes: [],
    semesters: [],
    turns: [],
  };

  timeFrom = {
    mins: '',
    hours: '',
    AmPm: '',
  };

  timeTo = {
    mins: '',
    hours: '',
    AmPm: '',
  };

  get amPm() {
    return [
      { name: this.$t('AM'), id: '0' },
      { name: this.$t('PM'), id: '1' },
    ];
  }

  reservasionData: ReservationRequest = {
    centerIds: [],
    turnId: '',
    academicYearId: '',
    examTypeId: '',
    semesterId: '',

    importStudentsCode: undefined,
    studentsCountData: undefined,
    examDateData: {
      from: '',
      to: '',
    },
    extraStudentsCount: undefined,
  };

  reservationDate = '';

  requestDate = false;

  courseTypeId = '';

  courseType = COURSE_TYPE;

  currentDate = new Date().toLocaleString('sv');

  SelectRules = [(v: number) => !!v || this.$t('requiredField')];

  CenterstRules = [(v: string[]) => (!!v.length) || this.$t('requiredField')];

  DateRules = [(v: string) => !!v || this.$t('requiredField')];

  dateErrMsg = false;

  reservationDateDiff = false;

  arrangedSelectedCenters: string[] = [];

  bookedTimeSlotsOnSpecificDate: BookedTimeSlot[] = [];

  showBookedTimeSlotsConflict = false;

  conflictedCenterIds: string[] = [];

  headerClass = 'grey darken-4 white--text pa-3 qb-table__header text-center';

  $refs!: {
    reservationForm: HTMLFormElement;
  };

  optionalNumberFieldRules(min, max) {
    return [
      (v: number) => (!v || v >= min) || `${this.$t('minValue')} ${min} `,
      (v: number) => (!v || v <= max) || `${this.$t('maxValue')}  ${max} `,
      (v: string) => (!v || /^[0-9]\d*$|^$/.test(v)) || this.$t('validations.NO_FRACTION'),
    ];
  }

  requiredNumberFieldRules(min, max) {
    return [
      (v: number) => !!v || this.$t('requiredField'),
      (v: number) => (v && v >= min) || `${this.$t('minValue')} ${min} `,
      (v: number) => (v && v <= max) || `${this.$t('maxValue')}  ${max} `,
      (v: string) => /^[0-9]\d*$|^$/.test(v) || this.$t('validations.NO_FRACTION'),
    ];
  }

  get studentsTypes() {
    return [
      { id: '0', baName: this.$t('IMPORT_ALL_STUDENTS_OPTION') },
      { id: '1', baName: this.$t('IMPORT_FRESHMEN_OPTION') },
      { id: '2', baName: this.$t('IMPORT_REMAINING_STUDENTS_OPTION') },
    ];
  }

  get disabledImport() {
    return (
      (this.courseTypeId === this.courseType.ACADIMIC_YEAR
        && !this.reservasionData.importStudentsCode)
      || !this.reservasionData.academicYearId
      || !this.reservasionData.semesterId
      || (this.courseTypeId === this.courseType.PGS_ACADEMIC
        && !this.reservasionData.turnId)
      || (((this.courseTypeId === this.courseType.ACADIMIC_YEAR
        && this.reservasionData.importStudentsCode)
        || this.courseTypeId !== this.courseType.ACADIMIC_YEAR)
        && this.reservasionData.academicYearId
        && this.reservasionData.semesterId
        && ((this.courseTypeId === this.courseType.PGS_ACADEMIC
          && this.reservasionData.turnId)
          || this.courseTypeId !== this.courseType.PGS_ACADEMIC)
        && Number(this.reservasionData.studentsCountData) > 0)
    );
  }

  get currentLocale() {
    return this.$store.state.lang.lang;
  }

  get formattedDate() {
    if (new Date(this.reservationDate) < new Date()) {
      return this.formatDate(
        this.currentDate.indexOf('T') !== -1
          ? this.currentDate.split('T')[0]
          : this.currentDate.split(' ')[0],
      );
    }
    return this.formatDate(this.reservationDate);
  }

  set formattedDate(val) {
    this.reservationDate = this.formatDate(val, true);
  }

  get filters() {
    return {
      academicYears: this.filtersData.academicYears?.map(
        (academicYear: ExamDropInput) => ({
          id: academicYear.baID,
          translatedName: this.$options.filters?.servertranslate(
            academicYear.baName
          ),
        })
      ),
      centers: this.filtersData.centers?.map((center: CenterDropInput) => ({
        id: center.baID,
        translatedName: this.$options.filters?.servertranslate(center.baName),
        totalCapacity: center.totalCapacity,
        limitedSectionsCapacity: center.limitedSectionsCapacity,
        unlimitedSectionsCapacity: center.unlimitedSectionsCapacity,
        bookedTimeSlots: center.bookedTimeSlots,
      })),
      examTypes: this.filtersData.examTypes?.map((examType: ExamDropInput) => ({
        degree: examType.degree,
        id: examType.baID,
        translatedName: this.$options.filters?.servertranslate(examType.baName),
      })),

      semesters: this.filtersData.semesters?.map((semester: ExamDropInput) => ({
        id: semester.baID,
        translatedName: this.$options.filters?.servertranslate(semester.baName),
      })),

      turns: this.filtersData.turns?.map((turn: ExamDropInput) => ({
        id: turn.baID,
        translatedName: this.$options.filters?.servertranslate(turn.baName),
      })),
    };
  }

  get formattedCenters(): { [key: string]: CenterDropInput } {
    return this.filters.centers.reduce((acc, center) => {
      if (!acc[String(center.id)]) {
        acc[String(center.id)] = center;
      }
      return acc;
    }, {});
  }

  get bookedTimeSlotsTableHeaders() {
    return [
      {
        text: this.$t('tableHeaders.index'),
        sortable: false,
        align: 'center',
        value: 'index',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.center'),
        sortable: false,
        value: 'center',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.DATE_TIME_FROM'),
        sortable: false,
        align: 'center',
        value: 'dateTimeFrom',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.DATE_TIME_TO'),
        sortable: false,
        align: 'center',
        value: 'dateTimeTo',
        class: this.headerClass,
      },
    ];
  }

  get lang() {
    return this.$store.state.lang.lang;
  }

  get conflictedCentersNames(): string {
    let centersNames = '';
    const conflictedCenterIdsLength: number = this.conflictedCenterIds.length;
    if (conflictedCenterIdsLength) {
      this.conflictedCenterIds.forEach((centerId, centerIndex) => {
        centersNames += this.formattedCenters[centerId].translatedName;
        if (conflictedCenterIdsLength !== (centerIndex + 1)) {
          centersNames += ' - ';
        }
      });
    }
    return centersNames;
  }

  isReservationHaveBookedTimeSlotConflict(): boolean {
    let isReservationHaveConflict = false;
    if (!(this.reservasionData && this.reservasionData.examDateData && this.reservasionData.examDateData.from && this.reservasionData.examDateData.to)) {
      return isReservationHaveConflict;
    }
    const dateTimeFrom = this.reservasionData.examDateData.from;
    const dateTimeTo = this.reservasionData.examDateData.to;
    this.reservasionData.centerIds.forEach((centerId) => {
      if (this.formattedCenters[centerId] && this.formattedCenters[centerId].bookedTimeSlots?.length) {
        if (this.isHaveDateTimeConflict(dateTimeFrom, dateTimeTo, this.formattedCenters[centerId].bookedTimeSlots || [])) {
          this.conflictedCenterIds = [...new Set([...this.conflictedCenterIds, centerId])];
          isReservationHaveConflict = true;
        }
      }
    });
    return isReservationHaveConflict;
  }

  isHaveDateTimeConflict(dateTimeFrom: string, dateTimeTo: string, bookedTimeSlots: BookedTimeSlot[]) {
    for (let i = 0; i < bookedTimeSlots.length; i += 1) {
      const bookedTimeSlot = bookedTimeSlots[i];
      if (new Date(bookedTimeSlot.bookingFromDate).toISOString() < new Date(dateTimeTo).toISOString() && new Date(bookedTimeSlot.bookingToDate).toISOString() > new Date(dateTimeFrom).toISOString()) {
        return true;
      }
    }
    return false;
  }

  convertDateTimeToLocalTime(dateTime: string, locales = undefined, options = { hour12: true, timeZone: 'Egypt' }) {
    return new Date(dateTime).toLocaleTimeString(locales, options);
  }

  convertDateTimeToLocalDate(dateTime: string, locales = undefined, options = { timeZone: 'Egypt' }) {
    return new Date(dateTime).toLocaleDateString(locales, options);
  }

  @Watch('reservationDate')
  formatBookedTimeSlotsOnDate() {
    this.bookedTimeSlotsOnSpecificDate = [];
    if (this.reservationDate && Array.isArray(this.reservasionData.centerIds) && this.reservasionData.centerIds.length) {
      const currentReservationDate = new Date(this.convertDateTimeToLocalDate(this.reservationDate));
      this.reservasionData.centerIds.forEach((centerId) => {
        if (this.formattedCenters[centerId] && this.formattedCenters[centerId].bookedTimeSlots && this.formattedCenters[centerId].bookedTimeSlots?.length) {
          (this.formattedCenters[centerId].bookedTimeSlots || []).forEach((bookedTimeSlot) => {
            const convertedBookedFromDate = new Date(this.convertDateTimeToLocalDate(bookedTimeSlot.bookingFromDate));
            const convertedBookedToDate = new Date(this.convertDateTimeToLocalDate(bookedTimeSlot.bookingToDate));
            if (currentReservationDate >= convertedBookedFromDate && currentReservationDate <= convertedBookedToDate) {
              this.bookedTimeSlotsOnSpecificDate.push({
                id: bookedTimeSlot.id,
                centerId,
                bookingFromDate: bookedTimeSlot.bookingFromDate,
                bookingToDate: bookedTimeSlot.bookingToDate,
              });
            }
          });
        }
      });
    }
  }

  changeSelectedCenters() {
    this.formatBookedTimeSlotsOnDate();
    if (this.reservasionData.centerIds && this.reservasionData.centerIds.length === 0) {
      this.arrangedSelectedCenters = [];
      return;
    }
    if (this.reservasionData.centerIds && this.reservasionData.centerIds.length) {
      this.reservasionData.centerIds.forEach((centerId) => {
        if (!this.arrangedSelectedCenters.includes(centerId)) {
          this.arrangedSelectedCenters.push(centerId);
        }
      });
    }
    if (this.arrangedSelectedCenters && this.arrangedSelectedCenters.length) {
      const tempArrangedSelectedCenters = JSON.parse(JSON.stringify(this.arrangedSelectedCenters));
      this.arrangedSelectedCenters.forEach((centerId) => {
        if (!this.reservasionData.centerIds.includes(centerId)) {
          const tempArrangedCenterIndex = tempArrangedSelectedCenters
            .findIndex((arrangedCenterId) => arrangedCenterId === centerId);
          tempArrangedSelectedCenters.splice(tempArrangedCenterIndex, 1);
        }
      });
      this.arrangedSelectedCenters = tempArrangedSelectedCenters;
    }
  }

  mounted() {
    this.courseId = this.$route.params.courseId;
    this.fetchQuestionData();
  }

  async fetchQuestionData() {
    try {
      this.loading = true;
      const { reserveExamFiltersData, CourseValidation } = await ReserveExamProvider.GetFilters(this.appId, this.courseId, {
        isAddFilters: true,
      });

      this.filtersData = reserveExamFiltersData as ReservationAddFiltersTypes;
      this.courseTypeId = CourseValidation.courseType;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.err = err;
    }
  }

  formatDate(date, reverse = false) {
    if (!date) return '';

    if (reverse) {
      const [year, month, day] = date.split('/');
      return `${year}-${month}-${day}`;
    }
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }

  async getStudentsCount() {
    try {
      this.importLoading = true;
      const params = {
        semesterId: this.reservasionData.semesterId,
        academicYearId: this.reservasionData.academicYearId,
        turnId: this.reservasionData.turnId ? this.reservasionData.turnId : '',
        importStudentsCode: this.reservasionData.importStudentsCode,
      };
      const { studentsCount }: ImportStudentsResponse = await ReserveExamProvider.importStudentsCount(
        this.appId,
        this.courseId,
        params,
      );

      this.reservasionData.studentsCountData = studentsCount;
      this.importLoading = false;
      if (studentsCount === 0) {
        this.infoToaster(this.$t('NO_STUDENTS_TO_WITHDRAW'), {
          duration: 5000,
        });
      }
    } catch (err) {
      this.importLoading = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  goBack() {
    const appFilters = this.$store.state.filters.appFilters[this.appId];
    this.$router.push({
      name: RESERVE_EXAM_ROUTE_NAME.RESERVE_EXAMS_LIST,
      query: appFilters,
      params: {
        courseId: this.courseId,
      },
    });
  }

  validateFormShowConfirm() {
    this.dateErrMsg = false;
    this.reservationDateDiff = false;
    this.showBookedTimeSlotsConflict = false;
    this.conflictedCenterIds = [];
    if (this.$refs.reservationForm.validate()) {
      const formalizedObject = this.formalizeRequestObject();
      const isReservationHaveConflict: boolean = this.isReservationHaveBookedTimeSlotConflict();
      if (!(this.validateDate(formalizedObject) || isReservationHaveConflict)) {
        this.showSaveConfirm = true;
      } else if (isReservationHaveConflict) {
        this.showBookedTimeSlotsConflict = true;
      }
    }
  }

  formalizeRequestObject() {
    let timeTo = '';
    let timeFrom = '';

    if (this.timeTo.AmPm === '0') {
      if (this.timeTo.hours !== '12') {
        timeTo = `${Number(this.timeTo.hours)}:${this.timeTo.mins}`;
      } else {
        timeTo = `00:${this.timeTo.mins}`;
      }
    } else if (this.timeTo.hours !== '12') {
      timeTo = `${Number(this.timeTo.hours) + 12}:${this.timeTo.mins}`;
    } else {
      timeTo = `${Number(this.timeTo.hours)}:${this.timeTo.mins}`;
    }

    if (this.timeFrom.AmPm === '0') {
      if (this.timeFrom.hours !== '12') {
        timeFrom = `${Number(this.timeFrom.hours)}:${this.timeFrom.mins}`;
      } else {
        timeFrom = `00:${this.timeFrom.mins}`;
      }
    } else if (this.timeFrom.hours !== '12') {
      timeFrom = `${Number(this.timeFrom.hours) + 12}:${this.timeFrom.mins}`;
    } else {
      timeFrom = `${Number(this.timeFrom.hours)}:${this.timeFrom.mins}`;
    }

    // this.reservasionData.examDateData.to = Utils.CONVERT_FROM_EG_DATE_TO_ISO_DATE(
    //   `${this.reservationDate} ${timeTo}`,
    // );

    // this.reservasionData.examDateData.from = Utils.CONVERT_FROM_EG_DATE_TO_ISO_DATE(
    //   `${this.reservationDate} ${timeFrom}`,
    // );

    this.reservasionData.examDateData.to = new Date(
      `${this.reservationDate} ${timeTo}`,
    ).toISOString();

    this.reservasionData.examDateData.from = new Date(
      `${this.reservationDate} ${timeFrom}`,
    ).toISOString();

    this.reservasionData.studentsCountData = Number(
      this.reservasionData.studentsCountData,
    );

    this.reservasionData.extraStudentsCount = Number(
      this.reservasionData.extraStudentsCount,
    );

    return this.reservasionData;
  }

  validateDate(formalizedObject) {
    if (formalizedObject.examDateData.from < new Date().toISOString()) {
      this.dateErrMsg = true;
      return true;
    }

    if (
      (+new Date(formalizedObject.examDateData.to)
        - +new Date(formalizedObject.examDateData.from))
        / 1000
      < 60
    ) {
      this.reservationDateDiff = true;
      return true;
    }
    return false;
  }

  async saveReservation() {
    try {
      this.showSaveConfirm = false;
      this.loading = true;
      this.reservasionData.centerIds = this.arrangedSelectedCenters;
      const { newExamRequestId, message }: SaveReservationResponse = await ReserveExamProvider.addReservedExam(
        this.appId,
        this.courseId,
        this.reservasionData,
      );

      this.loading = false;
      this.successToaster(message as Lang, { duration: 5000 });

      this.$router.push({
        name: RESERVE_EXAM_ROUTE_NAME.RESERVE_EXAM_DETAILS,
        params: {
          courseId: this.courseId,
          reserveExamId: newExamRequestId,
        },
      });
    } catch (err) {
      this.errorToaster(err as Lang, { duration: 5000 });

      this.loading = false;
    }
  }

  resetStudentsCount() {
    if (
      this.reservasionData.studentsCountData
      && !(this.courseTypeId === this.courseType.EXTERNAL_COURSE
      || this.courseTypeId === this.courseType.EXTERNAL_COURSE_PGS)
    ) {
      this.reservasionData.studentsCountData = 0;
      this.reservasionData.extraStudentsCount = 0;
    }
  }
}
